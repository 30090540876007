<template>
  <div class="payment-page">
    <div class="payment-page__bg-wrapper payment-page__top">
      <span class="payment-page__bg"></span>
      <div class="payment-page__bg-content">
        <Header />
        <Title>Оплатить заказ</Title>
        <form ref="cardForm" class="payment-page__form" novalidate @submit.prevent="validate">
          <h4 class="payment-page__title">
            Вы заказываете
            <template v-if="orders.length === 1">отчет из ЕГРН для объекта с кадастровым номером:</template>
            <template v-if="orders.length > 1">отчеты из ЕГРН для объектов с кадастровыми номерами:</template>
            <br />{{ cadNumbers }}
          </h4>
          <div id="payment-form"></div>
        </form>
        <Info />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/uniq/header/Header';
import Title from '@/components/gui/title/Title';
import Info from '@/components/views/payment/info/Info';

import sendCard from '@/api/sendCard';

import './payment.scss';

export default {
  name: 'Payment',
  components: {
    Header,
    Title,
    Info,
    Footer: () => import('@/components/uniq/footer/Footer'),
  },
  data() {
    return {
      showNoCardBlock: false,
      selectedServices: [],
    };
  },
  computed: {
    orders() {
      return this.$store.state.cartData;
    },
    cadNumbers() {
      return this.orders.reduce((acc, item, index) => {
        if (index) {
          acc += '; '
        }

        acc += item.object.CADNOMER;

        return acc;
      }, '')
    },
    cost() {
      return this.$store.state.config.cost;
    },
    price() {
      return this.selectedServices.length > 1
        ? this.priceWithoutDiscount * 0.8
        : this.priceWithoutDiscount;
    },
    priceWithoutDiscount() {
      let price = 0;

      this.selectedServices.forEach(type => {
        price += Number(this.cost[type]);
      });

      return price;
    }
  },
  async mounted() {
    this.selectedServices = this.getTypesOfOrder();

    const data = await sendCard({
      id: +(localStorage.getItem('payment_id')),
      cad_numbers: this.orders.reduce((acc, item) => {
        acc.push(item.object.CADNOMER);

        return acc;
      }, [])
    });

    const checkout = new window.YooMoneyCheckoutWidget({
      confirmation_token: data.confirmation_token, //Токен, который перед проведением оплаты нужно получить от ЮKassa
      return_url: `${window.location.origin}/finish_payment/${localStorage.getItem('payment_id')}`, //Ссылка на страницу завершения оплаты
      payment_methods: ['bank_card'],
      //Настройка виджета (при необходимости)
      customization: {
        //Настройка цветовой схемы, минимум один параметр, значения цветов в HEX
        colors: {
          //Цвет акцентных элементов: кнопка Заплатить, выбранные переключатели, опции и текстовые поля
          control_primary: '#00BF96' //Значение цвета в HEX
        }
      },
      error_callback: function() {
        alert('Произошла ошибка при попытке оплаты')
      }
    });

    checkout.render('payment-form');
  },
  methods: {
    getTypesOfOrder() {
      const arrTypes = [];

      this.orders.forEach(order => {
        for (const key in order.services) {
          if (Object.hasOwnProperty.call(order.services, key)) {
            order.services[key] && arrTypes.push(key);
          }
        }
      });

      return arrTypes;
    },
  }
};
</script>
